import React, { useState } from 'react';
import styled from 'styled-components';
import BloodPressureRecord from '../components/BloodPressureRecord';
import HeightAndWeightRecord from '../components/HeightAndWeightRecord';
import Header from '../components/Header';
import BloodSugarRecord from '../components/BloodSugarRecord';

const RecordContainer = styled.div<{ selectedmenuprop: number }>`
  /* display: flex;
  justify-content: center; */

  .recordMenuWrapper {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }

  .recordMenuSelector {
    border-radius: 10px;
    border: 1px solid black;
    margin: 0.1rem;
    padding: 1rem 0.5rem;
    text-align: center;
  }

  .firstSelector {
    background-color: ${(props) =>
      props.selectedmenuprop === 1 ? '#2fb893' : 'none'};
    color: ${(props) => (props.selectedmenuprop === 1 ? 'white' : 'black')};
  }
  .secondSelector {
    background-color: ${(props) =>
      props.selectedmenuprop === 2 ? '#2fb893' : 'none'};
    color: ${(props) => (props.selectedmenuprop === 2 ? 'white' : 'black')};
  }
  .thirdSelector {
    background-color: ${(props) =>
      props.selectedmenuprop === 3 ? '#2fb893' : 'none'};
    color: ${(props) => (props.selectedmenuprop === 3 ? 'white' : 'black')};
  }
`;

function Record() {
  const [selectedMenu, setSelectedMenu] = useState(1);
  return (
    <RecordContainer selectedmenuprop={selectedMenu}>
      <Header />
      <div className="recordMenuWrapper">
        <div
          className="recordMenuSelector firstSelector cp"
          onClick={() => setSelectedMenu(1)}
        >
          Blood Pressure
        </div>
        <div
          className="recordMenuSelector secondSelector cp"
          onClick={() => setSelectedMenu(2)}
        >
          Height & Weight
        </div>
        <div
          className="recordMenuSelector thirdSelector cp"
          onClick={() => setSelectedMenu(3)}
        >
          Blood Sugar
        </div>
      </div>
      {selectedMenu === 1 && <BloodPressureRecord />}
      {selectedMenu === 2 && <HeightAndWeightRecord />}
      {selectedMenu === 3 && <BloodSugarRecord />}
    </RecordContainer>
  );
}

export default Record;
