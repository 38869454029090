import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RecordInput from '../components/RecordInput';
import axios from '../axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../state/user';
import { RootState } from '../state/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useNavigate } from 'react-router-dom';

const BloodPressureRecordContainer = styled.div<{ readytosend: string }>`
  border: 1px solid #a3aca8;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 20vw;

  .recordInput {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 2rem;
    font-weight: 800;
  }

  .submit {
    font-size: 1rem;
    padding: 10px 0 0 0;
    span {
      padding: 5px;
      background-color: ${(props) => props.readytosend};
    }
  }

  @media (max-width: 500px) {
    margin: 20px 5vw;
  }
`;

function BloodPressureRecord() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);
  const [bloodPressureData, setBloodPressureData] = useState({ uid: user.uid });
  const [readyToSend, setReadyToSend] = useState<Boolean>(false);

  useEffect(() => {
    // console.log(
    //   'number of bloodPressureData',
    //   Object.keys(bloodPressureData).length
    // );

    if (Object.keys(bloodPressureData).length === 5) {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [bloodPressureData]);

  const recordInputDataHandler = (data: any) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    console.log(bloodPressureData);

    if (data.data.includes('-')) {
      // console.log(dayjs(data.data).utc().format('YYYY-MM-DD HH:mm:ss'));
      data.data = dayjs(data.data).utc().format('YYYY-MM-DD HH:mm:ss');
    } else {
      data.data = Number(data.data);
    }

    setBloodPressureData({
      ...bloodPressureData,
      [data.label]: data.data,
    });
  };

  const bloodPressureRecordHandler = async (data: object) => {
    console.log('bloodPressureRecordHandler', data);

    await axios
      .post('/data/addbp', data, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.data.status_code === 200) {
          alert('Good job! Successfully recorded.');
          navigate('/analysis', { replace: false });
        } else {
          alert('Oops! something is wrong.');
        }
      })
      .catch((response) => {
        // console.log('Error!', response);
      });
  };

  const bloodPressureColletcingDataGroup = [
    {
      key: 1,
      title: 'Time of Check',
      label: 'timeOfCheck',
      type: 'datetime-local',
      unit: '',
    },
    { key: 2, title: 'Sys', label: 'sys', type: 'number', unit: 'mmHg' },
    { key: 3, title: 'Dia', label: 'dia', type: 'number', unit: 'mmHg' },
    { key: 4, title: 'Pulse', label: 'pulse', type: 'number', unit: '/min' },
  ];

  return (
    <BloodPressureRecordContainer
      readytosend={readyToSend ? '#4eff4b' : '#e3e3e3'}
    >
      <div className="recordInput title">Blood Pressure</div>

      {bloodPressureColletcingDataGroup.map((data) => (
        <div className="recordInput" key={data.key}>
          <RecordInput
            dataHandler={recordInputDataHandler}
            title={data.title}
            label={data.label}
            type={data.type}
            unit={data.unit}
          />
        </div>
      ))}

      <div className="recordInput submit">
        <span
          onClick={() =>
            readyToSend && bloodPressureRecordHandler(bloodPressureData)
          }
          // onClick={() => console.log(bloodPressureData)}
          className="cp"
        >
          Submit
        </span>
      </div>
    </BloodPressureRecordContainer>
  );
}

export default BloodPressureRecord;
