import styled from 'styled-components';

type RecordInputProps = {
  dataHandler: Function;
  title: string;
  label: string;
  type: string;
  unit: string;
};

const RecordInputContainer = styled.div<{}>`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  position: relative;
  width: 300px;
  margin-top: 3rem;

  input {
    font-size: 15px;
    color: #222222;
    width: 300px;
    border: none;
    border-bottom: solid #aaaaaa 1px;
    padding-bottom: 10px;
    padding-left: 10px;
    position: relative;
    background: none;
    z-index: 5;
  }

  input::placeholder {
    color: #aaaaaa;
    text-align: right;
  }

  input:focus {
    outline: none;
  }

  span {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0%;
    background-color: #666;
    width: 0;
    height: 2px;
    border-radius: 2px;
    transition: 0.5s;
  }

  .label--title {
    position: absolute;
    color: #aaa;
    left: 10px;
    font-size: 20px;
    bottom: 8px;
    transition: all 0.2s;
  }

  .label--unit {
    position: absolute;
    color: #aaa;
    right: 10px;
    font-size: 15px;
    bottom: 8px;
  }

  input:focus ~ .label--title,
  input:valid ~ .label--title {
    font-size: 16px;
    bottom: 40px;
    color: #666;
    font-weight: bold;
  }

  input:focus ~ span,
  input:valid ~ span {
    width: 100%;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    input[type='search']::-webkit-search-clear-button,
    input[type='search']::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }

  /* input[type='datetime-local']::-webkit-datetime-edit-text {
    -webkit-appearance: none;
    display: none;
  }

  input[type='datetime-local']::-webkit-datetime-edit-month-field {
    -webkit-appearance: none;
    display: none;
  }

  input[type='datetime-local']::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
    display: none;
  }

  input[type='datetime-local']::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
  } */
  /* input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-calendar-picker-indicator,
input[type="week"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  display: none;
} */
`;

function RecordInput({
  dataHandler,
  title,
  label,
  type,
  unit,
}: RecordInputProps) {
  return (
    <RecordInputContainer>
      <input
        type={type}
        onChange={(e) => {
          console.log(label, e.target.value);
          dataHandler({ label: label, data: e.target.value });
        }}
        required
      />
      <label className="label--title">{title}</label>
      <label className="label--unit">{unit}</label>
      <span></span>
    </RecordInputContainer>
  );
}

export default RecordInput;
