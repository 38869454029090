import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RecordInput from '../components/RecordInput';
import axios from '../axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../state/user';
import { RootState } from '../state/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useNavigate } from 'react-router-dom';

const BloodSugarRecordContainer = styled.div<{ readytosend: string }>`
  border: 1px solid #a3aca8;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 20vw;

  .recordInput {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 2rem;
    font-weight: 800;
  }

  .submit {
    font-size: 1rem;
    padding: 10px 0 0 0;
    span {
      padding: 5px;
      background-color: ${(props) => props.readytosend};
    }
  }

  @media (max-width: 500px) {
    margin: 20px 5vw;
  }
`;

function BloodSugarRecord() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);

  const [bloodSugarRecordData, setBloodSugarRecordData] = useState({
    uid: user.uid,
  });
  const [readyToSend, setReadyToSend] = useState<Boolean>(false);

  useEffect(() => {
    if (Object.keys(bloodSugarRecordData).length === 3) {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [bloodSugarRecordData]);

  const recordInputDataHandler = (data: any) => {
    dayjs.extend(utc)
    dayjs.extend(timezone);
    console.log(bloodSugarRecordData);
    
    if (data.data.includes('-')) {
      // console.log(dayjs(data.data).utc().format('YYYY-MM-DD HH:mm:ss'));
      data.data = dayjs(data.data).utc().format('YYYY-MM-DD HH:mm:ss');
    } else {
      data.data = Number(data.data);
    }
    console.log(data);

    setBloodSugarRecordData({
      ...bloodSugarRecordData,
      [data.label]: data.data,
    });
  };

  const BloodSugarRecordHandler = async (data: object) => {
    console.log('BloodSugarRecordHandler', data);

    await axios
      .post('/data/addbs', data, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.data.status_code === 200) {
          alert('Good job! Successfully recorded.');
          navigate('/analysis', { replace: false });
        } else {
          alert('Oops! something is wrong.');
        }
      })
      .catch((response) => {
        // console.log('Error!', response);
      });
  };

  const BloodSugarColletcingDataGroup = [
    {
      key: 1,
      title: 'Time of Check',
      label: 'timeOfCheck',
      type: 'datetime-local',
      unit: '',
    },
    { key: 2, title: 'Glucose', label: 'glucose', type: 'number', unit: 'mg/dL' },
  ];

  return (
    <BloodSugarRecordContainer
      readytosend={readyToSend ? '#4eff4b' : '#e3e3e3'}
    >
      <div className="recordInput title">Blood Sugar</div>

      {BloodSugarColletcingDataGroup.map((data) => (
        <div className="recordInput" key={data.key}>
          <RecordInput
            dataHandler={recordInputDataHandler}
            title={data.title}
            label={data.label}
            type={data.type}
            unit={data.unit}
          />
        </div>
      ))}

      <div className="recordInput submit">
        <span onClick={() => readyToSend && BloodSugarRecordHandler(bloodSugarRecordData)} className="cp">Submit</span>
      </div>
    </BloodSugarRecordContainer>
  );
}

export default BloodSugarRecord;
