import React, { Fragment } from 'react';
import Header from '../components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../state/store';
import { userActions } from '../state/user';
import axios from 'axios';

function Main() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  // console.log(user);



  return (
    <Fragment>
      <Header />
      
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <img src="logo.png" alt="logo" />
      </div>
    </Fragment>
  );
}

export default Main;
