import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RecordInput from '../components/RecordInput';
import axios from '../axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../state/user';
import { RootState } from '../state/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useNavigate } from 'react-router-dom';

const HeightAndWeightRecordContainer = styled.div<{ readytosend: string }>`
  border: 1px solid #a3aca8;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 20vw;

  .recordInput {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 2rem;
    font-weight: 800;
  }

  .submit {
    font-size: 1rem;
    padding: 10px 0 0 0;
    span {
      padding: 5px;
      background-color: ${(props) => props.readytosend};
    }
  }

  @media (max-width: 500px) {
    margin: 20px 5vw;
  }
`;

function HeightAndWeightRecord() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);

  const [heightAndWeightRecordData, setHeightAndWeightRecordData] = useState({
    uid: user.uid,
  });
  const [readyToSend, setReadyToSend] = useState<Boolean>(false);

  useEffect(() => {
    if (Object.keys(heightAndWeightRecordData).length === 4) {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [heightAndWeightRecordData]);

  const recordInputDataHandler = (data: any) => {
    dayjs.extend(utc)
    dayjs.extend(timezone);
    console.log(heightAndWeightRecordData);
    
    if (data.data.includes('-')) {
      // console.log(dayjs(data.data).utc().format('YYYY-MM-DD HH:mm:ss'));
      data.data = dayjs(data.data).utc().format('YYYY-MM-DD HH:mm:ss');
    } else {
      data.data = Number(data.data);
    }
    console.log(data);

    setHeightAndWeightRecordData({
      ...heightAndWeightRecordData,
      [data.label]: data.data,
    });
  };

  const HeightAndWeightRecordHandler = async (data: object) => {
    console.log('HeightAndWeightRecordHandler', data);

    await axios
      .post('/data/addhw', data, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.data.status_code === 200) {
          alert('Good job! Successfully recorded.');
          navigate('/analysis', { replace: false });
        } else {
          alert('Oops! something is wrong.');
        }
      })
      .catch((response) => {
        // console.log('Error!', response);
      });
  };

  const HeightAndWeightColletcingDataGroup = [
    {
      key: 1,
      title: 'Time of Check',
      label: 'timeOfCheck',
      type: 'datetime-local',
      unit: '',
    },
    { key: 2, title: 'Height', label: 'height', type: 'number', unit: 'cm' },
    { key: 3, title: 'Weight', label: 'weight', type: 'number', unit: 'kg' },
  ];

  return (
    <HeightAndWeightRecordContainer
      readytosend={readyToSend ? '#4eff4b' : '#e3e3e3'}
    >
      <div className="recordInput title">Height & Weight</div>

      {HeightAndWeightColletcingDataGroup.map((data) => (
        <div className="recordInput" key={data.key}>
          <RecordInput
            dataHandler={recordInputDataHandler}
            title={data.title}
            label={data.label}
            type={data.type}
            unit={data.unit}
          />
        </div>
      ))}

      <div className="recordInput submit">
        <span onClick={() => readyToSend && HeightAndWeightRecordHandler(heightAndWeightRecordData)} className="cp">Submit</span>
      </div>
    </HeightAndWeightRecordContainer>
  );
}

export default HeightAndWeightRecord;
