import React from 'react';
import styled from 'styled-components';

const IntroContainer = styled.div<{ value: boolean }>`
  animation: ${(props) => (props.value ? 'none' : 'fadeout 2s')};
  animation-fill-mode: forwards;

  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const Container = styled.div<{}>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: beige;
  img {
    padding: 5%;
    width: 100vh;
  }
`;

function Intro() {
    window.localStorage.setItem("greeting", "done")
  return (
    <Container>
      <IntroContainer value={false}>
        <img src="/greeting.png" alt="greeting" />
      </IntroContainer>
    </Container>
  );
}

export default Intro;
