import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  dark: {
    backgrounds: `#454545`,
    main: `rgba(255,255,255,0.85)`,
    other: `rgba(255,255,255,0.65)`,
  },
  light: {
    backgrounds: `#fff`,
    main: `rgba(0, 0, 0, 0.85)`,
    other: `rgba(0, 0, 0, 0.75)`,
    point: `rgba(0, 0, 0, 0.02)`,
  },
};
