import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { RootState } from '../state/store';
import { userActions } from '../state/user';
import axios from '../axiosConfig';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const HeaderContainer = styled.header<{ toggled: string }>`
  margin: 0;

  a:-webkit-any-link {
    color: white;
    text-decoration: none;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #9a9a9a;
    padding: 8px 12px;
  }

  .navbar__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 28px;
      height: 28px;
      margin-right: 5px;
    }
  }

  .navbar__menu {
    display: flex;
    list-style: none;
    padding-left: 0;
  }

  .navbar__menu li {
    padding: 8px 12px;
    div {
      color: white;
    }
  }

  .navbar__menu li:hover {
    background-color: #3c3835;
    border-radius: 4px;
  }

  .navbar__toggleBtn {
    position: absolute;
    right: 20px;
    top: 12px;
    display: none;
  }

  @media screen and (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 24px;
    }

    .navbar__menu {
      display: none;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .navbar__menu li {
      width: 100%;
      text-align: center;
    }

    .navbar__icons {
      display: none;
      justify-content: center;
      width: 100%;
    }

    .navbar__toggleBtn {
      display: block;
    }

    .navbar__menu {
      display: ${(props) => props.toggled};
    }
  }
`;

function Header() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const dispatch = useDispatch();
  const [toggledValue, setToggledValue] = useState('none');
  const user = useSelector((state: RootState) => state.user.user);
  const auth = useSelector((state: RootState) => state.user.checkIn);

  useEffect(() => {
    // console.log(user);
    // console.log(auth);
    if (user.name === 'initiated') {
      authHandler(user);
    } else if (user.name !== 'guest' && dayjs().diff(dayjs(auth), 'minute') > 5) {
      console.log(dayjs().utc().diff(dayjs(auth).utc(), 'minute'), 'mins diff');
      authHandler(user);
    }
  }, [user]);

  const authHandler = async (data: object) => {
    console.log('authHandler', data);

    await axios
      .post('/users/auth', data, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        console.log(response);
        dispatch(userActions.change(response.data.resultData.user));
        dispatch(userActions.auth(dayjs().utc().format('YYYY-MM-DD HH:mm:ss')));
      })
      .catch((response) => {
        console.log('Error!', response);
        dispatch(userActions.change({ name: 'guest' }));
        // signoutHandler();
      });
  };

  const signoutHandler = async () => {
    const userId = user.name;
    await axios
      .post('/users/signout', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        console.log(response);
        dispatch(userActions.change({ name: 'guest' }));
      })
      .catch((response) => {
        console.log('Error!', response);
      });
  };

  return (
    <HeaderContainer toggled={toggledValue}>
      <nav className="navbar">
        <div className="navbar__logo">
          <div>
            <img src="logo.png" alt="logo" />
          </div>
          <Link to="/">
            <div>HealthTracker</div>
          </Link>
        </div>

        <ul className="navbar__menu">
          <li>
            <Link to="/">
              <div>Home</div>
            </Link>
          </li>
          {user.name === 'guest' ? (
            <li>
              <Link to="/signin">
                <div>Sign in</div>
              </Link>
            </li>
          ) : (
            <Fragment>
              <li>
                <Link to="/record">
                  <div>Record</div>
                </Link>
              </li>
              <li>
                <Link to="/analysis">
                  <div>Analysis</div>
                </Link>
              </li>
              <li>
                <div className="cp" onClick={() => signoutHandler()}>
                  Sign out
                </div>
              </li>
            </Fragment>
          )}

          {/* <li>S
            {user.name === 'guest' ? (
              <Link to="/signin">
                <div>Sign in</div>
              </Link>
            ) : (
              <div
                onClick={() => dispatch(userActions.change({ name: 'guest' }))}
              >
                Sign out
              </div>
            )}
          </li> */}
        </ul>

        <div className="navbar__toggleBtn">
          <div
            onClick={() =>
              setToggledValue(toggledValue === 'none' ? 'flex' : 'none')
            }
            className="menu__icon"
          >
            <img src="menu_icon.svg" alt="menu icon" />
          </div>
        </div>
      </nav>
    </HeaderContainer>
  );
}

export default Header;
