import dayjs from 'dayjs';
import ReactApexChart from 'react-apexcharts';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const AnalysisChart = (props) => {
  const { type, yaxis, collectedData } = props;

  dayjs.extend(utc);
  dayjs.extend(timezone);

  console.log('collectedData', collectedData);
  console.log(collectedData);

  let chartTitle = '';
  let chartDataX = [];
  let chartDataY1 = [];
  let chartDataY2 = [];
  let chartDataY3 = [];
  let chartAxisY = [chartDataY1, chartDataY2, chartDataY3];
  let seriesOnState = [];

  for (let index = 0; index < yaxis.length; index++) {
    seriesOnState.push({
      name: yaxis[index],
      data: chartAxisY[index],
    });
    console.log('index', index);
  }

  console.log('yaxis', yaxis);
  console.log('seriesOnState', seriesOnState);

  if (type === 'bp') {
    chartTitle = 'Blood Pressure'
    for (let index = 0; index < collectedData.length; index++) {
      chartDataX.push(
        dayjs(collectedData[index].time_of_check).utc().format('YYYY-MM-DD')
      );
      chartDataY1.push(collectedData[index].sys);
      chartDataY2.push(collectedData[index].dia);
      chartDataY3.push(collectedData[index].pulse);
    }

    console.log('chartDataX', chartDataX);
    console.log('chartDataY', chartDataY1);
  } else if (type === 'hw') {
    chartTitle = 'Height & Weight';
    // chartAxisY = 'Kg';
    for (let index = 0; index < collectedData.length; index++) {
      chartDataX.push(
        dayjs(collectedData[index].time_of_check).utc().format('YYYY-MM-DD')
      );
      chartDataY1.push(collectedData[index].height);
      chartDataY2.push(collectedData[index].weight);
    }
  } else if (type === 'bs') {
    chartTitle = 'Blood Sugar';
    chartAxisY = 'mg/dL';
    for (let index = 0; index < collectedData.length; index++) {
      chartDataX.push(
        dayjs(collectedData[index].time_of_check).utc().format('YYYY-MM-DD')
      );
      chartDataY1.push(collectedData[index].glucose);
    }
  }
  // else if (type === 'hw') {
  //   chartTitle = 'Height & Weight'
  //   chartAxisY = 'Kg'
  //   for (let index = 0; index < collectedData.length; index++) {
  //     chartData.push({
  //       ['x']: new Date(collectedData[index].time_of_check),
  //       ['y']: collectedData[index].weight,
  //     });
  //   }
  // } else if (type === 'bs') {
  //   chartTitle = 'Blood Sugar'
  //   chartAxisY = 'mg/dL'
  //   for (let index = 0; index < collectedData.length; index++) {
  //     chartData.push({
  //       ['x']: new Date(collectedData[index].time_of_check),
  //       ['y']: collectedData[index].glucose,
  //     });
  //   }
  // }

  const state = {
    series: seriesOnState,
    // series: [
    //   {
    //     name: 'High - 2013',
    //     data: chartDataY1,
    //   },
    //   {
    //     name: 'Low - 2013',
    //     data: chartDataY2,
    //   },
    //   {
    //     name: 'Low - 2013',
    //     data: chartDataY3,
    //   },
    // ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ['#77B6EA', '#d14e4e', '#509d52'],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
        text: chartTitle,
        align: 'left',
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: chartDataX,
        title: {
          text: '',
        },
      },
      yaxis: {
        title: {
          text: '',
        },
        min: 0,
        max: 180,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="line"
        height={350}
      />
    </div>
  );
};
export default AnalysisChart;
