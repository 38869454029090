import React, { useState } from 'react';
import styled from 'styled-components';
import Main from './pages/main';
import Intro from './pages/intro';
import { Route, Routes } from 'react-router-dom';
import Record from './pages/record';
import Analysis from './pages/analysis';
import Signin from './pages/signin';
import { useSelector } from 'react-redux';
import { RootState } from './state/store';

const Container = styled.div`
  /* background-color: ${(props) => props.theme.backgrounds};
  color: ${(props) => props.theme.main};


  @media (max-width: 500px) {
  } */
`;

function App() {
  const [pageSelector, setPageSelector] = useState(true);
  const greetingValue = window.localStorage.getItem('greeting');

  const user = useSelector((state: RootState) => state.user.user);

  if (!greetingValue) {
    setTimeout(() => setPageSelector(!pageSelector), 2000);
  }
  return (
    <Container>
      <Routes>
        {greetingValue ? (
          <Route path="/" element={<Main />} />
        ) : (
          <Route path="/" element={<Intro />} />
        )}
        <Route path="/record" element={user.name === 'guest' ? <Main /> :  <Record />} />
        <Route path="/analysis" element={user.name === 'guest' ? <Main /> :  <Analysis />} />
        <Route path="/signin" element={<Signin />} />
      </Routes>
    </Container>
  );
}

export default App;
