import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: { name: 'initiated', uid: 0 },
  checkIn: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    change: (state, action) => {
      state.user = action.payload;
    },
    auth: (state, action) => {
      state.checkIn = action.payload;
    }
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
